<template>
  <div class="main-content">
    <b-overlay :show="showOverlay" opacity="0.60" rounded="sm" variant='white' no-wrap></b-overlay>
    <h2 class="mb-4">Add User</h2>
    <b-row>
      <b-col md="12 mb-30">
        <b-card>
          <b-row>
            <b-form-group
              class="col-md-6 mb-3"
              label="First Name"
              label-for="firstName"
              :class="{ 'form-group--error': $v.form.firstName.$error }"
            >
              <b-form-input
                v-model="form.firstName"
                type="text"
                required
                placeholder="First Name"
                size="lg"
              ></b-form-input>
              <div class="error" v-if="$v.form.firstName.$error">
                Field is required
              </div>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Last Name"
              label-for="lastName"
              :class="{ 'form-group--error': $v.form.lastName.$error }"
            >
              <b-form-input
                v-model="form.lastName"
                type="text"
                required
                placeholder="Last Name"
                size="lg"
              ></b-form-input>
              <div class="error" v-if="$v.form.lastName.$error">
                Field is required
              </div>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Mobile Number"
              label-for="mobileNo"
            >
              <b-form-input
                v-model="form.mobileNo"
                type="text"
                required
                placeholder="Mobile Number"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Email"
              label-for="email"
              :class="{ 'form-group--error': $v.form.email.$error }"
            >
              <b-form-input
                v-model="form.email"
                type="email"
                required
                placeholder="Email"
                size="lg"
              ></b-form-input>
              <div class="error" v-if="$v.form.email.$error">
                Field is required
              </div>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Password"
              label-for="password"
            >
              <b-form-input
                v-model="form.password"
                type="password"
                required
                placeholder="Password"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Confirm Password"
              label-for="confirmPassword"
            >
              <b-form-input
                v-model="form.confirmPassword"
                type="password"
                required
                placeholder="Confirm Password"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Roles"
              label-for="roles"
            >
              <b-form-select
                v-model="form.role"
                :options="roles"
                required
                placeholder="Select Role"
                size="lg"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Profile Picture"
              label-for="profilePicture"
            >
              <b-form-file
                v-model="form.profilePicture"
                @change="previewImage"
                accept="image/*"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <div v-if="form.profilePictureData" class="mt-3">
                <b-img :src="form.profilePictureData" fluid alt="Profile Picture Preview" />
              </div>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Address"
              label-for="address"
            >
              <b-form-textarea
                v-model="form.address"
                required
                placeholder="Address"
                size="lg"
                rows="3"
              ></b-form-textarea>
            </b-form-group>

            

            <b-form-group
              class="col-md-6 mb-3"
              label="State"
              label-for="State"
            >
              <b-form-select
                v-model="form.state"
                :options="states"
                required
                placeholder="Select State"
                size="lg"
              ></b-form-select>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col md="12" class="d-flex justify-content-end">
              <b-button
                class="mt-3"
                variant="primary"
                @click="submit"
              >Submit</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  metaInfo: {
    title: "Add User",
  },
  data() {
    return {
      showOverlay: false,
      form: {
        firstName: "",
        lastName: "",
        mobileNo: "",
        email: "",
        password: "",
        confirmPassword: "",
        role: null,
        address: "",
        active: "active",
        profilePicture: null,
        profilePictureData: null
      },
      roles: [
        { value: null, text: 'Please select a role' },
        { value: 'admin', text: 'Admin' },
        { value: 'user', text: 'User' },
        { value: 'guest', text: 'Guest' }
      ],
      states: [
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'InActive' }
      ]
    };
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      mobileNo: { required },
      email: { required },
      password: { required },
      confirmPassword: { required, sameAs: 'password' },
      role: { required },
      address: { required }
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$bvToast.toast('Please fill required fields', {
          title: 'Error',
          variant: "danger",
          autoHideDelay: 5000,
        });
      } else {
        this.showOverlay = true;
        // Add your form submission logic here

        // Example submission logic:
        this.addUser(this.form)
          .then(response => {
            this.showOverlay = false;
            this.$bvToast.toast("User added successfully", {
              title: "Success",
              variant: "success",
              autoHideDelay: 5000,
            });
            this.$router.push('/users');
          })
          .catch(err => {
            this.showOverlay = false;
            this.$bvToast.toast(err.message || "An error occurred", {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            });
          });
      }
    },
    addUser(userData) {
      // This should be replaced with your actual API call
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ success: true });
        }, 1000);
      });
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = e => {
          this.form.profilePictureData = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
  }
};
</script>

<style scoped>
.form-group label {
  font-size: 14px !important;
}
.form-control {
  background: #ffffff;
}
</style>
